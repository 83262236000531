/*
 *
 * IP 2 RGB
 *
 */
let min = 0;
let max = 255;

window.rgbArr = [(Math.floor(Math.random() * (max - min)) + min),(Math.floor(Math.random() * (max - min)) + min),(Math.floor(Math.random() * (max - min)) + min)];

const ip_request = new XMLHttpRequest();
ip_request.open('GET', 'http://ip-api.com/json', true);

ip_request.onload = function reworkIP (callback) {

	if (ip_request.status >= 200 && ip_request.status < 400) {
	// Success!
		let data = JSON.parse(ip_request.responseText);
		//console.log(data);
		let ip_data = data.query.split('.');
		let reworked_ip_data = [];
		for (let i = 0; i < ip_data.length; i++) {
			reworked_ip_data.push(parseInt(ip_data[i]));
		}

		window.rgbArr = reworked_ip_data;

	} else {
		// We reached our target server, but it returned an error
		console.log('IP server ERR');
		window.rgbArr = [(Math.floor(Math.random() * (max - min)) + min),(Math.floor(Math.random() * (max - min)) + min),(Math.floor(Math.random() * (max - min)) + min)];
	}

};

ip_request.onerror = function() {
	// There was a connection error of some sort
	console.log('IP connection ERR');
	window.rgbArr = [(Math.floor(Math.random() * (max - min)) + min),(Math.floor(Math.random() * (max - min)) + min),(Math.floor(Math.random() * (max - min)) + min)];
};

ip_request.send();
