'use strict';

if (module.hot) {
  module.hot.accept();
}

//import 'babel-polyfill';
import '../styles/index.scss';
import './ip_to_rgb.js';
import './web_audio.js';
import { audioStart, audioStop, audioMousemove, audioUnmute, getDistance } from './web_audio.js';
import { getRandomRssImageData, setupBackgroundImage, getRandomRssTextData, setupTexts } from './rss_functions.js';

// webpack
/*import audio from '../audio.mp3';
import favicon from '../favicon.png';
import square from '../square.png';
import circle from '../circle.png';
import q_circle_l from '../q_circle_l.png';
import q_circle_r from '../q_circle_r.png';
import center from '../center.png';
import loading from '../loading.gif';*/

//console.log('before load');
//document.querySelector('.codeofarms').classList.add('visible');
//document.querySelector('.codeofarms').style.opacity = 1;

/*
 *
 * info content scroll
 *
 */
//let info_content_box = document.querySelector('.content.top');

/*let continuousScroll = setInterval(function () {
	if ((info_content_box.getBoundingClientRect().height + info_content_box.scrollTop) < info_content_box.scrollHeight) {
		info_content_box.scrollTop = info_content_box.scrollTop + 1;
	} else {
		info_content_box.scrollTop = 0;
	}
}, 20);*/

//audioStart();


window.onload = function () {

  let body = document.querySelector('body');
  body.style.height = window.innerHeight + 'px';


	function codeofarmsMousemove (mouse_event) {
    setupBackgroundImage(getRandomRssImageData('.feeds'), 0, document.documentElement, null);
		// fields follow cursor
    if (!Modernizr.touchevents) {
  		const selectors = [
  			'html',
  			'.main-overlay',
  			'.main-right-top > .inner',
  			'.main-left-top > .inner',
  			'.main-right-bottom > .inner',
  			'.main-left-bottom > .inner'
  		];
  		for (var i = selectors.length - 1; i >= 0; i--) {
  			if (selectors[i] == '.main-overlay') {
  				document.querySelector(selectors[i]).style.transform = 'translate('+0.15 * mouse_event.movementX+'px, '+0.15 * mouse_event.movementY+'px)';
  			} else if (selectors[i] == 'html') {
  				document.documentElement.style.backgroundPositionX = -0.1 * mouse_event.movementX+'px';
  				document.documentElement.style.backgroundPositionY = -0.1 * mouse_event.movementY+'px';
  			}
  			else {
  				document.querySelector(selectors[i]).style.transform = 'translate('+0.05 * mouse_event.movementX+'px, '+0.05 * mouse_event.movementY+'px)';
  			}
  		}
    }

		/*update background by distance to vp-center
		const vpW = window.innerWidth;
		const vpH = window.innerHeight;
		const mouse = { x: mouse_event.clientX, y: mouse_event.clientY };
		const center = { x: 0.5 * vpW, y: 0.5 * vpH };
		const corner = { x: vpW, y: vpH };
		const maxDistance = getDistance(center, corner);
		const distance = getDistance(center, mouse);
		const rel = parseInt(10000*(1 - Math.sqrt(distance / maxDistance)));
		console.log(rel);
		if (bgiInterval) {
			clearInterval(bgiInterval);
			bgiInterval = null;
		}

		let bgiInterval = setInterval(function () {
			setupBackgroundImage(getRandomRssImageData('.feed__d'), 0, document.documentElement, null);
		}, rel);*/
	}


	/*
	 *
	 * Info window
	 *
	 */

	let info_window = document.querySelector('.info');

	let open_info_btn = document.querySelector('.open-info-btn');
	let close_info_btn = document.querySelector('.close-info-btn');
	let close_info_link = document.querySelector('.close-info-link');

	let close_instructions_btn = document.querySelector('.close-instructions-button');
	let instructions_window = document.querySelector('.instructions');

	let menue_btn = document.querySelector('.menue-btn');
	let menue = document.querySelector('.menue');

	let codeofarms = document.querySelector('.codeofarms');

  let event_type_click = (Modernizr.touchevents) ? 'touchend' : 'click';
  let event_type_move = (Modernizr.touchevents) ? 'touchmove' : 'mousemove';

	menue_btn.addEventListener(event_type_click, function () {
		if (!menue.classList.contains('visible')) {
			menue.classList.add('visible');
		} else {
			menue.classList.remove('visible');
		}
	});

	open_info_btn.classList.add('visible');
	close_info_btn.classList.add('visible');
	close_info_link.classList.add('visible');

	//auto close
	setTimeout(function () {
		info_window.classList.remove('animate-in');
		info_window.classList.remove('visible');

		instructions_window.classList.add('animate-in');
		instructions_window.classList.add('visible');
	}, 60000);
	setTimeout(function () {
		instructions_window.classList.remove('animate-in');
		instructions_window.classList.remove('visible');
	}, 70000);

	codeofarms.addEventListener(event_type_click, function () {
		//audioStop();
		info_window.classList.add('animate-in');
		//setupTexts(getRandomRssTextData('.feeds'), info_window);
		setTimeout(function () {
			info_window.classList.add('visible');
		});
		setTimeout(function () {
			info_window.classList.remove('animate-in');
			info_window.classList.remove('visible');

			instructions_window.classList.add('animate-in');
			instructions_window.classList.add('visible');
		}, 60000);
		setTimeout(function () {
			instructions_window.classList.remove('animate-in');
			instructions_window.classList.remove('visible');
		}, 70000);
	});
	open_info_btn.addEventListener(event_type_click, function () {
		//audioStop();
		info_window.classList.add('animate-in');
		//setupTexts(getRandomRssTextData('.feeds'), info_window);
		setTimeout(function () {
			info_window.classList.add('visible');
		});
		setTimeout(function () {
			info_window.classList.remove('animate-in');
			info_window.classList.remove('visible');

			instructions_window.classList.add('animate-in');
			instructions_window.classList.add('visible');
		}, 60000);
		setTimeout(function () {
			instructions_window.classList.remove('animate-in');
			instructions_window.classList.remove('visible');
		}, 70000);
	});

	let audioHasStarted = false;

	close_info_btn.addEventListener(event_type_click, function () {
		if (!audioHasStarted) {
				audioStart();
				audioHasStarted = true;
		}
		info_window.classList.remove('animate-in');
		info_window.classList.remove('visible');

		instructions_window.classList.add('animate-in');
		instructions_window.classList.add('visible');

		// update text
		setTimeout(function () {
			instructions_window.classList.remove('animate-in');
			instructions_window.classList.remove('visible');
		}, 10000);
	});
	close_info_link.addEventListener(event_type_click, function () {
		if (!audioHasStarted) {
				audioStart();
				audioHasStarted = true;
		}
		info_window.classList.remove('animate-in');
		info_window.classList.remove('visible');

		instructions_window.classList.add('animate-in');
		instructions_window.classList.add('visible');

		// update text
		setTimeout(function () {
			instructions_window.classList.remove('animate-in');
			instructions_window.classList.remove('visible');
		}, 10000);

	});

	close_instructions_btn.addEventListener(event_type_click, function () {
		instructions_window.classList.remove('animate-in');
		instructions_window.classList.remove('visible');
	});


  let interactionNotice = document.querySelector('.interaction-notice');
  let mm_timeout;
  body.addEventListener(event_type_move, () => {
    if (Modernizr.touchevents) {
      setupBackgroundImage(getRandomRssImageData('.feeds'), 0, document.documentElement, null);
    }
    if (!interactionNotice.classList.contains('hidden')) {
        interactionNotice.classList.add('hidden');
    }
    clearTimeout(mm_timeout);
    mm_timeout = setTimeout(function () {
      if (interactionNotice.classList.contains('hidden') && !info_window.classList.contains('visible') && !instructions_window.classList.contains('visible')) {
          interactionNotice.classList.remove('hidden');
      }
      //window.location.reload();
    }, 5000);
  });


	/*
	 *
	 * readmore button
	 *
	 */

	/*let readmore_btn = document.querySelector('.readmore-btn');

	readmore_btn.classList.add('visible');

	readmore_btn.addEventListener(event_type_click, function (ev) {
		ev.preventDefault();
		// update text
		setupTexts(getRandomRssTextData('.feeds'), info_window);
		//background
		setupBackgroundImage(getRandomRssImageData('.feed__d'), 0, document.documentElement, null);
		//setupOuterImagery();
	});+/



	/* apply ip-data to elments */
	let boxShadow1Selectors = [
		'header',
		'main',
		'.main-overlay',
		'.main-right-top > .inner',
		'.main-left-top > .inner',
		'.main-right-bottom > .inner',
		'.main-left-bottom > .inner'
	];
	for (let i = boxShadow1Selectors.length - 1; i >= 0; i--) {
		document.querySelector(boxShadow1Selectors[i]).style.boxShadow = '0vw 0.25vw 1.25vw .25vw rgba('+window.rgbArr[0]+', '+window.rgbArr[1]+', '+window.rgbArr[2]+',.8)';
	}

	let boxShadow2Selectors = [
		'.main-left-top',
		'.main-right-bottom',
		'.main-right-top',
		'.main-left-bottom',
		'aside.left',
		'aside.right',
		'footer'
	];
	for (let i = boxShadow2Selectors.length - 1; i >= 0; i--) {
		document.querySelector(boxShadow2Selectors[i]).style.boxShadow = 'inset 0vw 0vw .75vw .25vw rgba('+(255-window.rgbArr[0])+', '+(255-window.rgbArr[1])+', '+(255-window.rgbArr[2])+',.5)';
	}

	let backgroundColor1Selectors = [
		'.main-left-top',
		'.main-right-bottom',
		'.main-right-top > .inner',
		'.main-left-bottom > .inner'
	];
	for (var i = backgroundColor1Selectors.length - 1; i >= 0; i--) {
		document.querySelector(backgroundColor1Selectors[i]).style.backgroundColor =  'rgba('+window.rgbArr[0]+', '+window.rgbArr[1]+', '+window.rgbArr[2]+',1)';
	}

	let backgroundColor2Selectors = [
		'.main-right-top',
		'.main-left-bottom',
		'.main-left-top > .inner',
		'.main-right-bottom > .inner'
	];
	for (var i = backgroundColor2Selectors.length - 1; i >= 0; i--) {
		document.querySelector(backgroundColor2Selectors[i]).style.backgroundColor = 'rgba('+(255-window.rgbArr[0])+', '+(255-window.rgbArr[1])+', '+(255-window.rgbArr[2])+',1)';
	}


	/*
	 *
	 * battery data
	 *


	navigator.getBattery().then(function(battery) {
		function updateAllBatteryInfo(){
			updateChargeInfo();
			updateLevelInfo();
			updateChargingInfo();
			updateDischargingInfo();
		}
		updateAllBatteryInfo();

		battery.addEventListener('chargingchange', function(){
			updateChargeInfo();
		});
		function updateChargeInfo(){
			console.log('Battery charging: ' + (battery.charging ? 'Yes' : 'No'));

			let saturation = battery.charging ? '2' : '.5';
			document.documentElement.style.filter = 'saturate('+ saturation +')';

			let beat_play_state = battery.charging ? 'running' : 'paused';
			document.querySelector('.animate-beat').style.animationPlayState = beat_play_state;
		}

		battery.addEventListener('levelchange', function(){
			updateLevelInfo();
		});
		function updateLevelInfo(){
			console.log('Battery level: ' + battery.level * 100 + '%');
			document.querySelector('.codeofarms').style.transform = 'scale('+ battery.level +')';
		}

		battery.addEventListener('chargingtimechange', function(){
			updateChargingInfo();
		});
		function updateChargingInfo(){
			console.log('Battery charging time: ' + battery.chargingTime + ' seconds');
		}

		battery.addEventListener('dischargingtimechange', function(){
			updateDischargingInfo();
		});
		function updateDischargingInfo(){
			console.log('Battery discharging time: ' + battery.dischargingTime + ' seconds');
		}

	});
	*/

	/*
	 *
	 * hardware data
	 *
	 */
	/*
	let ram_data = navigator.deviceMemory;
	console.log('RAM: '+ram_data+'GB');

	let cpu_data = navigator.deviceMemory;
	console.log(cpu_data+' CPU-Cores');
	*/





	function setupInnerImagery () {
    //main lt
		setupBackgroundImage(getRandomRssImageData('.feed__a'), 1200+200, document.querySelector('.main-left-top'), null);
		//main lt inner
		setupBackgroundImage(getRandomRssImageData('.feed__b'), 1200+200, document.querySelector('.main-left-top > .inner'), null);
		//main rt
		setupBackgroundImage(getRandomRssImageData('.feed__c'), 1200+400, document.querySelector('.main-right-top'), null);
		//main rt inner
		setupBackgroundImage(getRandomRssImageData('.feed__e'), 1200+200, document.querySelector('.main-right-top > .inner'), null);
		//main lb
		setupBackgroundImage(getRandomRssImageData('.feed__d'), 1600, document.querySelector('.main-left-bottom'), null);
		//main lb inner
		setupBackgroundImage(getRandomRssImageData('.feed__f'), 1200+200, document.querySelector('.main-left-bottom > .inner'), null);
		//main rb
		setupBackgroundImage(getRandomRssImageData('.feed__k'), 2000, document.querySelector('.main-right-bottom'), null);
		//main rb inner
		setupBackgroundImage(getRandomRssImageData('.feed__j'), 1200+200, document.querySelector('.main-right-bottom > .inner'), null);
		//main overlay
		setupBackgroundImage(getRandomRssImageData('.feeds'), 0, document.querySelector('.main-overlay'), null);
	}
	/*
	function setupOuterImagery () {
		//aside l
		setupBackgroundImage(getRandomRssImageData('.feed__b'), 1200+1600, document.querySelector('aside.left'), null);
		//aside r
		setupBackgroundImage(getRandomRssImageData('.feed__d'), 1200+1600, document.querySelector('aside.right'), null);
		//footer
		setupBackgroundImage(getRandomRssImageData('.feed__c'), 1200+1200, document.querySelector('footer'), null);
	}
	*/


if (Modernizr.touchevents) {
  let spans = document.querySelectorAll('.interaction-notice span');
  spans[0].innerHTML = 'Touch';
  spans[1].innerHTML = 'me';
  spans[2].innerHTML = 'now';
}


  window.addEventListener('focus', function () {
    if (!audioHasStarted) {
        audioStart();
        audioHasStarted = true;
    }

    document.documentElement.style.filter = 'saturate(1.5)';
    document.querySelector('.animate-beat').style.animationPlayState = 'running';
  });
  window.addEventListener('blur', function () {
    if (audioHasStarted) {
        audioStop();
        audioHasStarted = false;
    }

    document.documentElement.style.filter = 'saturate(.5)';
    document.querySelector('.animate-beat').style.animationPlayState = 'paused';
  });


	setTimeout(function(){
		/*if (!info_window.classList.contains('visible')) {
			audioStart();
		}*/

		body.addEventListener(event_type_move, function (ev) {
			audioMousemove(ev);
			codeofarmsMousemove(ev);
		});
    window.addEventListener('scroll', (ev) => {
      codeofarmsMousemove(ev);
    });
		document.documentElement.style.filter = 'saturate(1.5)';
		document.querySelector('.animate-beat').style.animationPlayState = 'running';

	}, 1200);


	//setupTexts(getRandomRssTextData('.feeds'), info_window);

	setupBackgroundImage(getRandomRssImageData('.feed__g'), 0, document.documentElement, null);

	//setupOuterImagery();

	setInterval(function () {
		setupInnerImagery();
	}, 2000/8);

	setInterval(function () {
		//header
		setupBackgroundImage(getRandomRssImageData('.feed__a'), 1200+1200, document.querySelector('header'), null);
	}, 2000/2);

};
