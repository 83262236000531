/*
 *
 * rss image functions
 *
 */

export function getRandomRssImageData (selector) {
	const rss_image_containers = document.querySelectorAll(selector);
	let rss_image_elements = [];

	for (let i = rss_image_containers.length - 1; i >= 0; i--) {
		rss_image_elements.push(...rss_image_containers[i].querySelectorAll('IMG'));
	}


	const rss_image_data_array = [];
	
	for (let i = rss_image_elements.length - 1; i >= 0; i--) {
		rss_image_data_array.push(rss_image_elements[i].src);
	}
	
	return rss_image_data_array[Math.floor((Math.random() * rss_image_elements.length) + 1)];
}

export function setupBackgroundImage (data, timeout, target_1, target_2) {
	if (data) {
		if (target_2 == null) {
			if (timeout) {
				setTimeout(function () {
					target_1.classList.remove('loading');
					target_1.style.backgroundImage = 'url('+data+')';
				}, timeout);
			} else {
				target_1.classList.remove('loading');
				target_1.style.backgroundImage = 'url('+data+')';
			}
		} else {
			if (timeout) {
				setTimeout(function () {
					target_1.classList.remove('loading');
					target_1.style.backgroundImage = 'url('+data+')';
				}, timeout);
				setTimeout(function () {
					target_1.querySelector(target_2).remove('loading');
					target_1.querySelector(target_2).style.backgroundImage = 'url('+data+')';
				}, timeout + 200);
			} else {
				target_1.querySelector(target_2).classList.remove('loading');
				target_1.style.backgroundImage = 'url('+data+')';
				target_1.querySelector(target_2).classList.remove('loading');
				target_1.querySelector(target_2).style.backgroundImage = 'url('+data+')';
			}
		}
	} else {
		if (target_2 == null) {
			if (timeout) {
				setTimeout(function () {
					target_1.classList.add('loading');
					target_1.style.backgroundImage = 'url(http://asmr.breitbart.blue/loading.gif)';
				}, timeout);
			} else {
				target_1.classList.add('loading');
				target_1.style.backgroundImage = 'url(http://asmr.breitbart.blue/loading.gif)';
			}
		} else {
			if (timeout) {
				setTimeout(function () {
					target_1.classList.add('loading');
					target_1.style.backgroundImage = 'url(http://asmr.breitbart.blue/loading.gif)';
				}, timeout);
				setTimeout(function () {
					target_1.querySelector(target_2).classList.add('loading');
					target_1.querySelector(target_2).style.backgroundImage = 'url(http://asmr.breitbart.blue/loading.gif)';
				}, timeout + 200);
			} else {
				target_1.classList.add('loading');
				target_1.style.backgroundImage = 'url(http://asmr.breitbart.blue/loading.gif)';
				target_1.querySelector(target_2).classList.add('loading');
				target_1.querySelector(target_2).style.backgroundImage = 'url(http://asmr.breitbart.blue/loading.gif)';
			}
		}
	}
}


/*
 *
 * rss text functions
 *
 */

export function getRandomRssTextData (selector) {
	const rss_text_container = document.querySelector(selector);
	const rss_text_headings = rss_text_container.querySelectorAll('h4') || rss_text_container.querySelectorAll('p');
	const rss_text_bodys = rss_text_container.querySelectorAll('blockquote') || rss_text_container.querySelectorAll('p');

	let rss_text_collection = [];
	for (var i = rss_text_bodys.length - 1; i >= 0; i--) {
		rss_text_collection.push({ head : rss_text_bodys[i].innerHTML, body : rss_text_headings[i].innerHTML });
	}

	return rss_text_collection;
}

export function setupTexts (data, target) {
	target.querySelector('.content.bottom').classList.remove('loading');
	let target_headings = target.querySelectorAll('h2');
	let target_bodys = target.querySelectorAll('.body');
	let target_footers = target.querySelectorAll('.footer');

	for (var i = target_headings.length - 1; i >= 0; i--) {
		target_headings[i].innerHTML = data[Math.floor((Math.random() * target_headings.length - 1) + 1)].head;
		target_bodys[i].innerHTML = data[Math.floor((Math.random() * target_headings.length - 1) + 1)].body;
		target_footers[i].innerHTML = getRandomTime()+' — ♥ '+Math.floor((Math.random() * (target_headings.length-i+1)*100) + (target_headings.length-i)*100);
	}
	
}

function getRandomTime () {
	let now = new Date();
	let hour = parseInt(now.getHours());
	let minute = parseInt(now.getMinutes());
	let day = now.getDate();
	let month = now.getMonth()+1;
	const year = now.getFullYear();

	hour = Math.floor((Math.random() * hour) + 1);
	minute = Math.floor((Math.random() * minute) + 1);
	hour = (hour < 10) ? '0'+hour : hour;
	minute = (minute < 10) ? '0'+minute : minute;
	
	day = (day < 10) ? '0'+day : day;
	month = (month < 10) ? '0'+month : month; 

	now = day +'.'+month+'.'+year+' | '+hour+':'+minute;

	return now;
}