/*
 *
 * web-audio
 *
 */


let sourceBuffer = void 0;
let filter = void 0;
let context = void 0;
let audio_request = void 0;
let vpW = void 0;
let vpH = void 0;
let MIN_PITCH = 0.4;
let MAX_PITCH = 1;

export function audioStart() {
	//var sound = './audio_' + Math.floor((Math.random() * 7) + 1) + '.mp3'
	let sound = './public/audio.mp3';

	context = new (window.AudioContext || window.webkitAudioContext)(); // define audio context
	// Webkit/blink browsers need prefix, Safari won't work without window.
	audio_request = new XMLHttpRequest();
	//request.abort()

	audio_request.open('GET', sound, true);
	audio_request.responseType = 'arraybuffer';
	audio_request.onload = function () {
		const undecodedAudio = audio_request.response;
		context.decodeAudioData(undecodedAudio, function (buffer) {
			sourceBuffer = context.createBufferSource();
			sourceBuffer.buffer = buffer;
			sourceBuffer.connect(context.destination);
			sourceBuffer.playbackRate.value = 1;
			//sourceBuffer.volume = 0;
			sourceBuffer.loop = true;
			sourceBuffer.start(context.currentTime);
			// Create the filter
			filter = context.createBiquadFilter();
			// Create the audio graph.
			sourceBuffer.connect(filter);
			filter.connect(context.destination);
			// Create and specify parameters for the low-pass filter.
			filter.type = 'lowpass'; // Low-pass filter. See BiquadFilterNode docs
			filter.frequency.value = 440; // Set cutoff to 440 HZ
			filter.Q.value = 10;
		});
	};
	audio_request.send();

	return true;
}
export function audioStop(timeout) {
	audio_request.abort();
	audio_request = null;

	context.close();
	context = null;

	return false;
}

/*export function audioVolume(scroll_ev) {

	console.log(scroll_ev);
	console.log(audio_request);

	const inc = 0.01;
	if (up) {
		const cur = audio_request.volume;
		if (cur <= 0.99) {
			audio_request.volume = this.volIndex.getAttribute('data-vol');
			audio_request.volume += inc;
			this.volIndex.setAttribute('data-vol', audio_request.volume);
			this.volIndex.innerHTML = Math.round(audio_request.volume*100)+'%';
		} else {
			audio_request.volume = 1.00;
			this.volIndex.setAttribute('data-vol', 1.00);
			this.volIndex.innerHTML = '100%';
		}
	} else {
		const cur = this.volIndex.getAttribute('data-vol');
		if (cur >= 0.01) {
			audio_request.volume = this.volIndex.getAttribute('data-vol');
			audio_request.volume -= inc;
			this.volIndex.setAttribute('data-vol', audio_request.volume);
			this.volIndex.innerHTML = Math.round(audio_request.volume*100)+'%';
		} else {
			audio_request.volume = 0.00;
			this.volIndex.setAttribute('data-vol', 0.00);
			this.volIndex.innerHTML = '0%';
		}
	}
}*/

export function audioUnmute() {
	if (audio_request.volume == 0.00) {
		audio_request.volume == 1.00;
	}
}

export function audioMousemove(mouse_event) {
	vpW = window.innerWidth;
	vpH = window.innerHeight;

	const mouse = { x: mouse_event.clientX, y: mouse_event.clientY };
	const center = { x: 0.5 * vpW, y: 0.5 * vpH };
	const corner = { x: vpW, y: vpH };
	const maxDistance = getDistance(center, corner);
	const distance = getDistance(center, mouse);
	const rel = distance / maxDistance;

	if (filter) {
		changeFrequency(rel);
		sourceBuffer.playbackRate.value = .1 + 1 - rel; // pitch-up / -down
	}
}
function changeFrequency(val) {
	// Clamp the frequency between the minimum value (40 Hz) and half of the
	// sampling rate.
	const minValue = 10000;
	const maxValue = 100;
	// Logarithm (base 2) to compute how many octaves fall in the range.
	const numberOfOctaves = Math.log(maxValue / minValue) / Math.LN2;
	// Compute a multiplier from 0 to 1 based on an exponential scale.
	const multiplier = Math.pow(2, numberOfOctaves * (val - 1.0));
	// Get back to the frequency value between min and max.
	filter.frequency.value = maxValue * multiplier;
}
export function getDistance(p1, p2) {
	const xDistance = p1.x - p2.x;
	const yDistance = p1.y - p2.y;
	return Math.sqrt(xDistance * xDistance + yDistance * yDistance);
}
